import React, { useState, useRef, useEffect } from 'react'
import { EmptyState } from '../../react_admin/EmptyState'

import {
    TextField,
    DateField,
    Filter,
    ReferenceInput,
    useListFilterContext,
    FunctionField,
    useListContext,
} from 'react-admin'
import { Button, TextField as MuiTextField, Box } from '@mui/material'
import {
    RunPhaseField,
    FilterSelectInput,
    FilterAutoCompleteInput,
    FilterSelectArrayInput,
} from '../../common'
import LinkField from '../../LinkField'
import { DateTimePicker } from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import RunBulkActionButtons from '../List/BulkEdit/RunBulkActionButtons'
import BaseDatagrid from '../../react_admin/BaseDatagrid'
import BaseList from './BaseList'
import { SUBGRID_RECORDS_PER_PAGE } from '../../../appConfigs'

const RunFilter = ({
    storeKey,
    isSuperUser,
    hideFilters = [], // array of filters to hide, can include 'integration', 'tenant', 'trigger'
    isSourceControl,
    integrationReferenceInputFilter = {},
    tenantReferenceInputFilter = {},
}) => {
    if (!isSuperUser) {
        integrationReferenceInputFilter.type__not_equal = 'PANDIUM'
        tenantReferenceInputFilter.integration__type__not_equal = 'PANDIUM'
    }
    const { filterValues, setFilters } = useListFilterContext({ storeKey })
    // the DateTimePickers need special state vars to hold the entire datetime object,
    // there is lag if the string that is stored in filterValues is passed to the value prop on the component.
    const [startDateValue, setStartDateValue] = useState(
        filterValues.started_date__gt
            ? dayjs(filterValues.started_date__gt)
            : null
    )
    const [endDateValue, setEndDateValue] = useState(
        filterValues.started_date__lt
            ? dayjs(filterValues.started_date__lt)
            : null
    )
    const [integrationId, setIntegrationId] = useState(null)
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                marginTop: 2,
                marginBottom: !isSourceControl && -2,
            }}
        >
            <Filter>
                {!hideFilters.includes('integration') && (
                    <ReferenceInput
                        source="integration_id"
                        reference="integrations"
                        filter={integrationReferenceInputFilter}
                        alwaysOn
                        label="Integration"
                        perPage={50}
                    >
                        <FilterAutoCompleteInput
                            optionText="name"
                            setFilters={setFilters}
                            onChange={(id) => {
                                setIntegrationId(id)
                            }}
                            filterToQuery={(q) => ({ name__contains: q })}
                        />
                    </ReferenceInput>
                )}
                {!hideFilters.includes('tenant') && (
                    <ReferenceInput
                        source="tenant_id"
                        reference="tenants"
                        alwaysOn
                        label="Tenant"
                        perPage={50}
                        sort={{ field: 'name', order: 'ASC' }}
                        filter={
                            integrationId
                                ? {
                                      integration_id: integrationId,
                                      ...tenantReferenceInputFilter,
                                  }
                                : tenantReferenceInputFilter
                        }
                    >
                        <FilterAutoCompleteInput
                            optionText="name"
                            setFilters={setFilters}
                            filterToQuery={(q) => ({ name__contains: q })}
                        />
                    </ReferenceInput>
                )}
                <FilterSelectInput
                    source="mode"
                    alwaysOn
                    choices={[
                        { id: 'init', name: 'Init' },
                        { id: 'normal', name: 'Normal' },
                        { id: 'webhook', name: 'Webhook' },
                    ]}
                    setFilters={setFilters}
                />
                {!hideFilters.includes('trigger') && (
                    <FilterSelectArrayInput
                        source="trigger"
                        alwaysOn
                        choices={[
                            { id: 'cron', name: 'Cron' },
                            { id: 'webhook', name: 'Webhook' },
                            { id: 'manual', name: 'Manual' },
                            { id: 'api', name: 'API' },
                            { id: 'rerun', name: 'Rerun' },
                        ]}
                        setFilters={setFilters}
                    />
                )}
                <FilterSelectArrayInput
                    source="status__phase"
                    label="Status"
                    alwaysOn
                    choices={[
                        { id: 'Succeeded', name: 'Succeeded' },
                        {
                            id: 'Failed (Integration Issue)',
                            name: 'Failed (Integration Issue)',
                        },
                        {
                            id: 'Failed (Timeout)',
                            name: 'Failed (Timeout)',
                        },
                        {
                            id: 'Failed (Platform Issue)',
                            name: 'Failed (Platform Issue)',
                        },
                        { id: 'In Progress', name: 'In Progress' },
                        { id: 'Initializing', name: 'Initializing' },
                        { id: 'Automatic Retry', name: 'Automatic Retry' },
                    ]}
                    setFilters={setFilters}
                />
                <Box
                    alwaysOn
                    sx={{
                        alignItems: 'center',
                        gap: 2,
                        display: 'flex',
                    }}
                >
                    <DateTimePicker
                        key="start"
                        label="Start"
                        alwaysOn
                        value={startDateValue || null}
                        onChange={(newValue) => {
                            setFilters({
                                ...filterValues,
                                started_date__gt: newValue?.toString(),
                            })
                            setStartDateValue(newValue)
                        }}
                        disableFuture
                        minDateTime={dayjs().subtract(1, 'month')}
                        maxDateTime={endDateValue}
                        renderInput={(params) => (
                            <MuiTextField
                                {...params}
                                variant="outlined"
                                sx={(theme) => ({
                                    '& > div.MuiInputBase-root': {
                                        height: '38px',
                                        borderRadius: 0,
                                        width: 'auto',
                                        '&:hover': {
                                            '& > fieldset': {
                                                border: '2px solid',
                                                borderColor:
                                                    theme.PandiumColors
                                                        .bluepurple,
                                            },
                                        },
                                    },
                                    minWidth: '150px',
                                })}
                            />
                        )}
                    />
                    <DateTimePicker
                        key="end"
                        label="End"
                        alwaysOn
                        value={endDateValue || null}
                        onChange={(newValue) => {
                            setFilters({
                                ...filterValues,
                                started_date__lt: newValue?.toString(),
                            })
                            setEndDateValue(newValue)
                        }}
                        disableFuture
                        minDateTime={
                            startDateValue || dayjs().subtract(1, 'month')
                        }
                        renderInput={(params) => (
                            <MuiTextField
                                {...params}
                                variant="outlined"
                                sx={(theme) => ({
                                    '& > div.MuiInputBase-root': {
                                        height: '38px',
                                        borderRadius: 0,
                                        width: 'auto',
                                        '&:hover': {
                                            '& > fieldset': {
                                                border: '2px solid',
                                                borderColor:
                                                    theme.PandiumColors
                                                        .bluepurple,
                                            },
                                        },
                                    },
                                    minWidth: '150px',
                                })}
                            />
                        )}
                    />
                </Box>
                <Button
                    onClick={() => {
                        setFilters({})
                        setStartDateValue(null)
                        setEndDateValue(null)
                    }}
                    alwaysOn
                    sx={{
                        letterSpacing: '1.5px',
                        mb: '2px',
                    }}
                    disabled={Object.keys(filterValues).length === 0}
                >
                    Clear Filters
                </Button>
            </Filter>
        </Box>
    )
}

// this is exported so that it can be used by RunList.test.js
export const DataGrid = ({
    tenant,
    storeKey,
    hideColumns = [], // array of columns to hide, can include 'id', 'integration', 'tenant', 'trigger'
    emptyStateText,
    isSourceControl,
    ...rest
}) => {
    const { filterValues, refetch } = useListContext({ storeKey })

    // after the component mounts, refetch the runs every time the tenant's lastRun changes.
    //this is dependent on the parent component polling the tenant.
    const mounted = useRef(false)
    useEffect(() => {
        if (tenant && mounted.current) {
            refetch()
        } else {
            mounted.current = true
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tenant?.status?.lastRun, tenant?.status?.currentRun])

    return (
        <BaseDatagrid
            rowClick="show"
            empty={
                <EmptyState
                    emptyStateText={
                        Object.keys(filterValues ?? {}).length > 0
                            ? 'No runs found matching the selected filters.'
                            : emptyStateText ??
                              'Welcome to Pandium!\nCreate a tenant to view and manage runs.'
                    }
                />
            }
            {...rest}
            data={rest.data}
            bulkActionButtons={
                isSourceControl ? false : <RunBulkActionButtons />
            }
        >
            {!hideColumns.includes('id') && (
                <TextField source="id" label="ID" />
            )}
            {isSourceControl ? (
                <FunctionField
                    label={'INTEGRATION'}
                    sortable={false}
                    render={(record) =>
                        record.trigger !== 'webhook'
                            ? record.triggers?.[0]?.payload?.headers[
                                  'x-build-integration-name'
                              ] ?? record.jobSpec?.tenant?.configs?.force_build
                            : 'See Logs'
                    }
                />
            ) : (
                !hideColumns.includes('integration') && (
                    <TextField
                        source="jobSpec.integration.name"
                        label={'INTEGRATION'}
                    />
                )
            )}
            {isSourceControl && (
                <FunctionField
                    label={'RELEASE TAG'}
                    sortable={false}
                    render={(record) =>
                        record.trigger !== 'webhook'
                            ? record.triggers?.[0]?.payload?.headers[
                                  'x-release-tag'
                              ] ?? record.jobSpec?.tenant?.configs?.tag_name
                            : 'See Logs'
                    }
                />
            )}
            {!hideColumns.includes('tenant') && (
                <TextField source="jobSpec.tenant.name" label="TENANT" />
            )}
            <TextField source="mode" label={'MODE'} />
            {!hideColumns.includes('trigger') && (
                <TextField source="trigger" label={'TRIGGER'} />
            )}
            <DateField source="startedDate" label="STARTED" showTime />
            <DateField source="completedDate" label="COMPLETED" showTime />
            <RunPhaseField source="status.phase" label="STATUS" />
            <LinkField
                // a.detailLink is defined in the theme styles in appConfigs.js, to allow us to target
                // this field for the hover effect
                className="detailLink"
                basePath="runs"
                redirect="show"
                sortable={true}
                variant="outlined"
                text="SHOW LOGS"
            />
        </BaseDatagrid>
    )
}

export const RunDataGrid = ({
    isSuperUser,
    isSourceControl = false,
    header,
    TopToolbar,
    storeKey,
    perPage = SUBGRID_RECORDS_PER_PAGE,
    sort = { field: 'startedDate', order: 'DESC' },
    filter,
    integrationReferenceInputFilter,
    tenantReferenceInputFilter,
    hideFilters,
    hideColumns,
    tenant, // used to check if a refetch should be triggered
    refetchInterval = false,
    emptyStateText,
    ...rest
}) => {
    return (
        <BaseList
            TopToolbar={TopToolbar}
            resource="runs"
            perPage={perPage}
            sort={sort}
            filter={filter}
            storeKey={storeKey}
            // prevents table from disappearing when loading
            // but does cause a lag when filtering or paging
            keepPreviousData={true}
            refetchInterval={refetchInterval}
            {...rest}
        >
            {header && (
                <Box
                    sx={(theme) => ({
                        color: theme.PandiumColors.darkgrey,
                        fontSize: '20px',
                        marginBottom: '10px',
                    })}
                >
                    {header}
                </Box>
            )}
            <RunFilter
                isSuperUser={isSuperUser}
                storeKey={storeKey}
                hideFilters={hideFilters}
                isSourceControl={isSourceControl}
                integrationReferenceInputFilter={
                    integrationReferenceInputFilter
                }
                tenantReferenceInputFilter={tenantReferenceInputFilter}
            />
            <DataGrid
                storeKey={storeKey}
                tenant={tenant}
                isSourceControl={isSourceControl}
                hideColumns={hideColumns}
                emptyStateText={emptyStateText}
                {...rest}
            />
        </BaseList>
    )
}
