import React from 'react'
import {
    ListContextProvider,
    ListToolbar,
    ResourceContextProvider,
    useListController,
} from 'react-admin'
import ListPagination from './ListPagination'

/**
 * A Pandium styled List component that includes the TopToolbar, Divider, Pagination.
 * @param children
 * @param TopToolbar
 * @param perPage
 * @param filters (react component to filter list)
 * @returns {*}
 */
export default ({
    children,
    TopToolbar,
    filters,
    perPage,
    keepPreviousData = false,
    refetchInterval = false,
    resource,
    sort,
    filter,
    actions,
    ...rest
}) => {
    const controllerProps = useListController({
        disableSyncWithLocation: true,
        resource,
        sort,
        filter,
        perPage,
        queryOptions: {
            keepPreviousData: keepPreviousData,
            refetchInterval: refetchInterval,
        },
        ...rest,
    })

    // TopToolbar needs to be nested between ResourceContextProvider and ListContextProvider
    // so that it is rerendered when data is refetched (so that the last updated date on RunList is correct).
    // Otherwise, ListBase could be used here
    return (
        <ResourceContextProvider value={resource}>
            {TopToolbar && <TopToolbar />}
            <ListContextProvider value={controllerProps}>
                {(actions || filters) && (
                    <ListToolbar actions={actions} filters={filters} />
                )}{' '}
                {children}
                <ListPagination perPageCustom={perPage} />
            </ListContextProvider>
        </ResourceContextProvider>
    )
}
