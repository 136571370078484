import React from 'react'
import { Typography } from '@mui/material'
import { format } from 'date-fns'
import { RUNS_PER_PAGE } from '../../../appConfigs'
import { connect } from 'react-redux'
import TopToolbar from '../List/ListTopToolbar'
import { RunDataGrid } from '../List/RunDataGrid'

const RunListTopToolbar = () => {
    const now = new Date()
    const timestamp = format(now, 'M/dd/yyyy, h:mm:ss aa')
    return (
        <>
            <TopToolbar pageTitle="Runs">
                <Typography
                    variant="caption"
                    sx={{
                        color: 'grey',
                        flexGrow: '4',
                        alignSelf: 'center',
                        marginLeft: '10px',
                    }}
                >
                    Last updated: {timestamp}
                </Typography>
            </TopToolbar>
        </>
    )
}

const RunList = ({ isSuperUser }) => {
    const pandiumFilter = !isSuperUser
        ? { integration__type__not_equal: 'PANDIUM' }
        : {}

    return (
        <RunDataGrid
            isSuperUser={isSuperUser}
            TopToolbar={RunListTopToolbar}
            storeKey="mainList"
            perPage={RUNS_PER_PAGE}
            filter={pandiumFilter}
            hideColumns={['id']}
            refetchInterval={30000}
        />
    )
}

const mapStateToProps = (state) => {
    return {
        isSuperUser: state.org.isSuperUser,
    }
}

export default connect(mapStateToProps)(RunList)
