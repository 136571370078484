import React from 'react'
import Paper from '@mui/material/Paper'

const classes = {
    emptyStateBox: {
        backgroundColor: '#F7FAFF',
        border: '1px solid',
        borderColor: '#DBDEE5',
        height: '696px',
        width: 'auto',
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '20px',
    },
    textStyle: {
        fontFamily: 'RobotoCondensedRegular',
        fontSize: '16px',
        color: 'rgba(0,0,0,0.6)',
        whiteSpace: 'pre-line',
    },
}

export const EmptyState = ({ emptyStateText: EmptyStateText, sx }) => {
    return (
        <Paper
            elevation={0}
            sx={{ ...classes.emptyStateBox, ...sx }}
            classvariant="outlined"
        >
            <div style={classes.textStyle}>
                <span>{EmptyStateText}</span>
            </div>
        </Paper>
    )
}
